.content{
    display: flex;
    justify-content: space-between;
    max-width: 40%;
    margin: 0 ;
    padding: 10px 0;
}


.btn_hover{
    margin-left: 10%;
     width: auto;
}

.btn_hover:hover{
    transition: unset !important;
}

.Preview{
    background-color: #FFFF;
}

.contentdiv{
    margin-left: 5%;
}

.content_list{
    display: flex;
    justify-content: space-between ;
    max-width: 80%;
    margin: 0 ;
    padding: 10px 0;
}


.content_right{
    margin-left: 15%;
    width: 80%;
}

.content_left{
    width: 80%;
}


.bold-text{
    margin-top: 5%;
}

.Pdfimg{
    height: 50%;
    width: 50%;
    margin-left: 25%;
    margin-top: 10%;
}

.dashboard__bookingcard{ 
    width: 40%;
    height: auto;
}

.propasal{
    margin-top: 2%;
}

.Book_Deatilas h1{
    margin-top: 10px;
    color: #DC5B5B;
}

.Input_file{
    width: 90%;
}

.preview_btn Button{
    margin-left: 14%;
}

.typography-card{
    margin-top: 10%;
}


.Remark{
  margin-top: 5%;
    height: fit-content;
}

.HeadLine{
    color: #DC5B5B;
}

.bold-text{
    margin-bottom: 1%;
}

.typograph{
    height: auto;
}

.btn-group--icons{
    margin-bottom: 1%;
}