  @media screen and (min-width: 768px) {
    .containerr {
        width: 100%;
        margin-bottom: 40px;
        max-width: 1630px;
    }
  }

  @media screen and (min-width: 320px) {
    .containerr {
        width: 100%;
        margin-bottom: 40px;
        max-width: 1630px;
        padding-left: 0%;
        padding-right: 0%;
    }
  }

  @media screen and (min-width: 320px) {
    .Nav_item{
        margin-right:-1%;
    }
  }


  @media screen and (min-width: 320px) {
    .Nav_item {
        margin-right:-1%;
    }
  }

  @media screen and (min-width: 768px) {
    .Nav_item {
        margin-right: 45%;
    }
  }

.a.active.Nav-link{
    color: blueviolet;
}