.input_era{
    width: 100%;
}

.newTemplateBtn{
    background-color: #FFFFFF ; 
  color: black; 
  border: 2px solid #4CAF50;
}


.newTemplateBtn:hover{
    background-color: #4CAF50;
  color: white;
}

.custom-modal-style {
  width: 897px;
  height: 802px;
  }
